import React, { useEffect } from "react"
import { graphql } from "gatsby"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ShareButtons from "../components/ShareButtons"
import BusinessListingCard from "../components/BusinessListingCard"
import {
  Box
} from "@chakra-ui/core"

export default function Template({ data }) {
  const { businessListing } = data
  const { fields } = businessListing || {}
  const { slug } = fields || {}
  
  useEffect(() => {
    firebase.analytics().setCurrentScreen(`listings/${slug}`)
  }, [])

  return (
    <Layout>
      <SEO title={businessListing.name} />
      <BusinessListingCard listing={businessListing} url={`https://www.laudiumtoday.co.za/listings/${slug}`} isDetailView />
      <Box mt='4'/>
      <ShareButtons
        title={businessListing.name}
        url={`https://www.laudiumtoday.co.za/listings/${slug}`}
        twitterHandle={"LaudiumToday"}
        tags={["LaudiumToday", "news", "Business directory"]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    businessListing(id: { eq: $id }) {
      id
      fields {
        slug
      }
      name
      description
      address
      contactNumber
      alternateContactNumber
      emailAddress
      brochureRef
      brochureUrl
      businessListingCategories {
        name
        id
      }
      logoUrl
      videoLink
      website
      whatsapp
      facebook
      instagram
      twitter
      tradingHours {
        Friday {
              closed
              day
              end
              index
              start
              additional
            }
            Friday_Additional {
              closed
              day
              end
              index
              start
            }
        Monday {
          closed
          day
          index
          end
          start
        }
        Saturday {
          closed
          day
          end
          index
          start
        }
        Sunday {
          start
          index
          end
          day
          closed
        }
        Thursday {
          start
          index
          end
          day
          closed
        }
        Tuesday {
          start
          index
          end
          day
          closed
        }
        Wednesday {
          start
          index
          end
          day
          closed
        }
      }
    }
  }
`
